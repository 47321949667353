// import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./pages/routes";
import style from "./app.module.scss";
// import Loading from './pages/components/loading/loading';
import { AuthProvider } from './pages/context/auth/auth';
import { useAppSelector } from "./pages/store/slice/hook";


const AppWrapper = () => {
    // const idToken = useAppSelector((state) => state.shopline.shoplineToken);
    const isValid = useAppSelector((state) => state.shopline.isValid);

    // const AuthFilter=({data}:any)=>{
    //     if(data){
    //         return <CustomRoutes/>
    //     }else{
    //         return <div>ddd</div>
    //     }
    // }
    // console.log(isValid)
    return(
        // <BrowserRouter>
            <AuthProvider>
                <div className={style.container}>
                    {/* {!isLoading && isValid && <CustomRoutes/>} */}
                    { isValid &&<CustomRoutes/>}
                    {/* {AuthFilter(isValid)} */}
                </div> 
            </AuthProvider>
        // </BrowserRouter>
    )
}

export default AppWrapper;