import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "../store";

// Define a type for the slice state
interface TokenState {
  shoplineToken: string;
  isValid: boolean;
  isLoading: boolean;
  isError : string;
  secretToken:boolean;
  merchantId:string;
}

// Define the initial state using that type
const initialState: TokenState = {
  shoplineToken: "",
  isValid: false,
  isLoading: false,
  isError :'',
  secretToken:false,
  merchantId:'',
};

export const tokenSlice = createSlice({
  name: "token",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    tokenswitch: (state, action: PayloadAction<string>) => {
      state.shoplineToken = action.payload;
    },
    validswitch: (state, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
    loadingswitch: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    errorswitch: (state, action: PayloadAction<string>) => {
      state.isError = action.payload;
    },
    secretswitch: (state, action: PayloadAction<boolean>) => {
      state.secretToken = action.payload;
    },
    merchantswitch: (state, action: PayloadAction<string>) => {
      state.merchantId = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //     state.value += action.payload
    // }
  },
});

export const {
  tokenswitch,
  validswitch,
  loadingswitch,
  errorswitch,
  secretswitch,
  merchantswitch,
} = tokenSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.token.value;

export default tokenSlice.reducer;