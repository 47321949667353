import React, { FC } from 'react';
import style from './button.module.scss';

interface Button {
    title: string;
    bgcolor: 'grey' | 'green' | 'yellow' | 'red' | 'none' | 'none_grey';
    disabled?: boolean;
    height?: string;
    pic?: string;
    fn?: any;
    type?: 'button' | 'submit' | 'reset';
}

const Button: FC<Button> = ({ title, bgcolor, disabled, height, pic, fn, type }) => {
    const handleColor = (data: string | undefined) => {
        switch (data) {
            case 'grey': return `${style.grey}`
            case 'green': return `${style.green}`
            case 'yellow': return `${style.yellow}`
            case 'red': return `${style.red}`
            case 'none': return `${style.none}`
            case 'none_grey': return `${style.none_grey}`
            default: return ''
        }
    }
    const handleClick = () => {
        if (fn) {
            fn();
        }
    }

    return (
        <div className={style.buttonWrap}>
            <button
                type={type ? type : 'submit'}
                className={handleColor(bgcolor)}
                disabled={disabled}
                style={{ height: height ? height : '36px' }}
                onClick={handleClick}
            >
                {pic && <div className={style.pic} style={{ backgroundImage: `url(${pic})` }}></div>}
                {title}
            </button>
        </div>
    )
}

export default Button;