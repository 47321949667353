import {useState,useEffect} from 'react';
import style from './index.module.scss';
import { useAppSelector,useAppDispatch } from '../store/slice/hook';
import Alert from '../components/alert/alert';
import { errorswitch} from "../store/slice/shopline";

const Error=()=>{
    const [errMess,setErrMess]=useState<boolean>(false);
    const [err,setErr]=useState('');
    const [errStatus,setErrStatus]=useState<any>('error');

    const errMessage = useAppSelector<string>(state => state.shopline.isError);
    const dispatch = useAppDispatch();

    const errorFn=(d1:boolean,d2:string,d3:string)=>{
        setErrMess(d1);
        setErr(d2); 
        setErrStatus(d3);
    }
    
    useEffect(()=>{
        if(errMessage!==''){
            errorFn(true,errMessage,'error');
            dispatch(errorswitch(''));
        }
    },[]);
    return(
        <>
            <div className={style.errorWrap}>抱歉！您沒有權限瀏覽此頁面</div>  
            <Alert toggle={errMess} status={errStatus} message={err} backToggle={(data)=>{setErrMess(data)}}></Alert>
        </>
        
    )
}

export default Error;