import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

export default function CircularColor() {
    return (

        // {/* <CircularProgress color="secondary" /> */}
        // {/* <CircularProgress color="success" /> */}
      // <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
      //   <CircularProgress color="inherit" />
      // </Stack>
       <Stack sx={{ width: '100%', color: 'rgb(54, 61, 77)' }} spacing={2}>
          <LinearProgress color='inherit' />
       </Stack>
    );
  }