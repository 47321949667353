import {FC} from 'react';
import {useLocation,Outlet,Navigate} from 'react-router-dom';
import { useAppSelector } from "../../store/slice/hook";

// interface Auth{
    
// }

const RequireAuth=()=>{
    const location=useLocation();
    const st = useAppSelector<boolean>(state => state.shopline.secretToken);
    const ld = useAppSelector<boolean>(state => state.shopline.isLoading);
    
    return (
        st
            ?<Outlet/>
            // : !ld && invalid
            : !ld ? <Navigate to='/error' state={{from:location}} replace/>
            :<></>
    )
}


export default RequireAuth;