import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;
const base_url = axios.create({ baseURL: `${apiURL}` });
// const base_url = axios.create({ baseURL: `https://ca3a-36-227-212-23.ngrok-free.app` });

// const dispatch = useAppDispatch();

base_url.interceptors.request.use(
    (req) => {
      // const user = localStorage.getItem('user');
      // if (user && user.includes("")) {
      //     req.headers['Authorization'] = 'bearer ' + JSON.parse(user);
      // }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  
  base_url.interceptors.response.use(
    (res) => {
      // console.log(res)
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        // console.log(err.message)
        // console.log("token過期");
        // Cookies.remove('package_t');
        // Cookies.remove('package_n');
        // Cookies.remove('package_p');
        // Cookies.remove('package_e');
        // Cookies.remove('t_k');
        // Cookies.remove('s_tk');
        // setTimeout(() => {
        //     window.location.replace('/login')
        // }, 3000)
      }
      return Promise.reject(err);
    }
  );
  
  const url = {
    base_url,
  };
  
  export default url;