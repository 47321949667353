import url from "./index";

const getAccess = (data) => {
    return url.base_url({
      method: "post",
      url: "/api/super2shopline/shopLineVerifyToken",
      headers: {
        authorization: `Bearer ${data.sessionToken}`,
      },
    });
  };

  const userSetting = (data) => {
    return url.base_url({
      method: "post",
      url: "/api/super2shopline/userSetting",
      headers: {
        authorization: `Bearer ${data.token}`,
      },
      data:{
        "merchant_id":data.id,
        "plan":data.plan,
        "delivery_option_id_json":data.optionId,
        'ts':data.ts
      }
    });
  };

  const getUser = (data) => {
    return url.base_url({
      method: "post",
      url: "/api/super2shopline/getUserSetting",
      headers: {
        authorization: `Bearer ${data.token}`,
      },
      data:{
        "merchant_id":data.id,
        'ts':data.ts
      }
    });
  };


  const auth_api = {
    getAccess,
    userSetting,
    getUser,
  };
  
  export default auth_api;