import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useScrollTrigger } from '@mui/material';

interface State extends SnackbarOrigin {
    open: boolean;
}

interface Alert{
    toggle:boolean;
    message:string;
    status:'error'|'warning'|'info'|'success';
    backToggle:(data:boolean)=>void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function PositionedSnackbar({toggle,message,status,backToggle}:Alert) {
    const [state, setState] = React.useState<State>({
      open: false,
      vertical: 'bottom',
      horizontal: 'left',
    });
    const { vertical, horizontal, open } = state;
    // const [backToggle,setBacktoggle]=React.useState<boolean>(false);
  
    // const handleClick = (newState: SnackbarOrigin) => () => {
    //   setState({ ...newState, open: true });
    // };
  
    const handleClose = () => {
        if(open===true&&backToggle){
           setState({ ...state, open: false });
           backToggle(false); 
        }
    };
    
    React.useEffect(()=>{
        if(toggle===true){
           setState({ ...state, open: true }); 
        }
    },[toggle]);

    // React.useEffect(()=>{
    //     if(open===false){
    //         backToggle(false);
    //     }
    // },[open]);
  
  
    return (
      <Box sx={{ width: 500 }}>
        {/* {buttons} */}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message=""
          key={vertical + horizontal}
        >
            <Alert severity={status} sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
      </Box>
    );
  }

  {/* <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}