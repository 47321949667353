import {useEffect} from 'react';
import appBridge from "../../components/bridge";
import { useAppDispatch } from "../../store/slice/hook";
import { tokenswitch,validswitch,loadingswitch,secretswitch,merchantswitch ,errorswitch} from "../../store/slice/shopline";
import Auth from '../../api/auth';
import md5 from 'md5';

export const AuthProvider=({children}:any)=>{
    const dispatch = useAppDispatch();

    console.log(process.env.REACT_APP_ENV);

    //後端token認證
    const getTokenInfo=async (token:string)=>{
        dispatch(loadingswitch(true));
        try{
            const data={
                sessionToken:token
            }
            const res=await Auth.getAccess(data);
            const resData=res.data;
            dispatch(loadingswitch(false));
            if(resData.data){
                // console.log(md5(`${resData.data.ts}`+process.env.REACT_APP_PRIVE_KEY));
                const s_token= md5(`${resData.data.ts}`+process.env.REACT_APP_PRIVE_KEY);
                // console.log(s_token);
                if(s_token!==resData.data.token){
                    dispatch(secretswitch(false));
                }else{
                    dispatch(merchantswitch(resData.data.merchant_id));
                    dispatch(secretswitch(true));
                }
            }
        }catch(e:any){
            console.log(e);
            dispatch(loadingswitch(false));
            dispatch(errorswitch(e.message));
        }
    }

    useEffect(() => {
        const appBridgeInit = async () => {
            dispatch(loadingswitch(true));
            const initResult = await appBridge.init({
                // clientId: '43a8119588f600d7ecb4353116d63b4de1ae9bb85a5eb06c47600be49ebd0810',
                clientId: process.env.REACT_APP_CLIENTID,
            });
            // @ts-ignore
            const sessionToken = await appBridge?.client?.getSessionToken();
    
            // console.log(sessionToken )
            if (initResult === "success" && sessionToken !== "") {
                dispatch(tokenswitch(sessionToken));
                dispatch(loadingswitch(false));
                dispatch(validswitch(true));
                getTokenInfo(sessionToken);
                 // dispatch(secretswitch(true));
                return;
                
            }
            console.log('bridge invalid')
            dispatch(loadingswitch(false));
            dispatch(validswitch(false));
        };
    
       appBridgeInit();
      }, []);

      return <div>{children}</div>;
}
