import AppWrapper from "./appWrapper";
import './App.css';

function App() {
  return (
    <AppWrapper />
  );
}

export default App;
