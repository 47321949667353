import { AppBridge as AppBridgeModule } from "@shopline/app-bridge-js";


let instance: AppBridge | null = null;


class AppBridge {
  client: unknown;
  constructor() {
    if (instance) {
      return instance;
    }


    instance = this;
  }


  async init(initConfig:any) {
    // 實作 timeout 機制
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Promise timed out"));
      }, 5000);
    });


    return Promise.race([AppBridgeModule.init(initConfig), timeoutPromise])
      .then((client) => {
        this.client = client;


        return "success";
      })
      .catch(() => "failed");
  }
}



const appBridgeInstance = new AppBridge();


export default appBridgeInstance;
