import {useState,Fragment,SyntheticEvent, useEffect} from 'react';
import style from './home.module.scss';
// import Switch from '../components/switch/switch';
import Radio from '../components/radio/radio';
import Input from '../components/input/input';
import Create from "../assets/create.svg";
import Trash from "../assets/trash.svg";
import Button from '../components/button/button';
import Alert from '../components/alert/alert';
import Loading from '../components/loading/loading';
import Auth from '../api/auth';
import md5 from 'md5';
import { useAppSelector ,useAppDispatch} from '../store/slice/hook';
import { loadingswitch} from "../store/slice/shopline";

interface Listcard{
    data2:{
        inner: any;
        id:string;
};
    handleItem:(data:string)=>void;
    callback:[];
    inx:number;
    filterId:(data:string)=>void;
}

const Home=()=>{
    // const [active,setActive]=useState<boolean>(false)
    const [radioway,setRadioway]=useState<string|number>('');
    const [items,setItems]=useState<any>([]);
    const [errMess,setErrMess]=useState<boolean>(false);
    const [err,setErr]=useState('');
    const [errStatus,setErrStatus]=useState<any>('error');
    const [ra,setRa]=useState<string|number>('');
    const [val,setVal]=useState('');

    const merchant_id = useAppSelector<string>(state => state.shopline.merchantId);
    const isLoading = useAppSelector((state) => state.shopline.isLoading);
    const dispatch = useAppDispatch();

    const addItem=(inner:string)=>{
        setItems([{id:Date.now(),inner:inner},...items]);
    };

    const removeItem=(inner:string)=>{
        setItems(items.filter((data:any)=>data.id!==inner))
    };
    
    const ts=Math.floor(Date.now() / 1000);
    const s_token=md5(`${ts}`+process.env.REACT_APP_PRIVE_KEY);

    const name_reg = /^\w*$/g; //英文與數字
    const fn_name_reg = val.match(name_reg);

    const errorFn=(d1:boolean,d2:string,d3:string)=>{
        setErrMess(d1);
        setErr(d2); 
        setErrStatus(d3);
    }
    
    
    const GetUser=async()=>{
        dispatch(loadingswitch(true));
        try{
            const item=JSON.stringify(items.filter((data: { inner: any; })=>data.inner!=='')
            .map((data: { inner: any; })=>data.inner));
            
            const data={
                token:s_token,
                id:merchant_id,
                // id:'64e851b6f72dc2003ba0bd90',
                ts:ts,
            }
            const res=await Auth.getUser(data);
            const resData=res.data;
            dispatch(loadingswitch(false));
            if(resData.data.data){
                if(resData.data.data.delivery_option_id_json!==''){
                    const arr=JSON.parse(resData.data.data.delivery_option_id_json);
                    const b=arr.map((data:string,inx:number)=>{
                        return {id:Date.now()+inx,
                         inner:data}
                     })
                    setItems(b)
                    // console.log( arr.map((inner:any,inx:number)=>setItems([...items,{id:inx,inner:inner}])))
                    
                }
                if(resData.data.data.plan!==''){
                    setRadioway(resData.data.data.plan);
                }
            }
        }catch(e:any){
            console.log(e)
            dispatch(loadingswitch(false));
            errorFn(true,e.message,'error');
        }
    }
    
    const UserSetting=async()=>{
        dispatch(loadingswitch(true));
        
        try{
            const item=JSON.stringify(items.filter((data: { inner: any; })=>data.inner!=='')
            .map((data: { inner: any; })=>data.inner));
            
            const data={
                token:s_token,
                id:merchant_id,
                ts:ts,
                plan:Number(ra),
                optionId:item
            }
            const res=await Auth.userSetting(data);
            const resData=res.data;
            dispatch(loadingswitch(false));
            if(resData){
                errorFn(true,'成功送出ID資訊','success');
                setVal('');
                // (async()=>{
                //     await GetUser();  
                // })()
            }
        }catch(e:any){
            console.log(e)
            dispatch(loadingswitch(false));
            errorFn(true,e.message,'error');
        }
    }
    
    const handleSubmit=(e:SyntheticEvent<HTMLFormElement, SubmitEvent>)=>{
        e.preventDefault();
        if(ra!==''){
            if(val!==''){
                errorFn(true,'請確認ID是否正確添加','warning');
                return
            }
            UserSetting();
        }else{
           errorFn(true,'請選取設置方式','error');
        }
        
    }
    
    const handleClick=()=>{
        if(val!=='' && fn_name_reg){
            const fil=items.filter((data:any)=>data.inner===val);
            if(fil.length!==0){
                errorFn(true,'ID重複，請重新輸入ID','error');
                return
            }
            addItem(val);
            setVal('');
        }else{
            errorFn(true,'請輸入正確格式之id','error');
        }
    }
    
    useEffect(()=>{
        GetUser();


        // const arr=JSON.parse("[\"aa\",\"bb\"]");
        // const b=arr.map((data:string,inx:number)=>{
        //     return {id:Date.now()+inx,inner:data}
        // })
        // // setItems([...items].concat(b))
        // setItems(b)
        // setRadioway('1');


    },[]);
    // console.log(items)
    useEffect(()=>{
        setRa(`${radioway}`);
    },[radioway]);

    return (
        <div className={style.homeWrap}>
            {
                isLoading
                &&
                <div className={style.loadingWrap}>
                    <Loading></Loading>
                </div>
            }
            
            <div className={style.header}>
                <h1>PackAge+ 循環包裝</h1>
            </div>
            <div className={style.content}>
                <div className={style.switchTitle}>
                    <h3>PackAge+ 循環包裝設置</h3>
                    {/* <Switch back={(data:boolean)=>{setActive(data)}} ></Switch> */}
                </div>
                <div className={style.titleContent}>
                    <p>目前有兩種方式讓你的顧客可以選用配客嘉循環包裝，請選擇其一並完成設定。<br/>
                    前往幫助中心文章<a href='https://package-plus.com/2023/12/how-to-install-package-circular-packaging-on-shopline/' target='_blank'>(PackAge+ 永續循環包裝設定指南)</a>，瞭解如何設定。</p>
                </div>
                <div className={style.formWrap}>
                    <form action="" onSubmit={handleSubmit}>
                        <div className={style.custom}>
                            <Radio  back={(data)=>{setRa(data)}} raWay={ra}  val={'0'}></Radio>
                            <div className={style.innerContent}>
                                <p>設置為訂單自訂欄位</p>
                                {/* <a href="">(訂單自訂欄位設置指南)</a>  */}
                            </div>
                        </div>
                        <div className={style.custom}>
                            <Radio  back={(data)=>{setRa(data)}} raWay={ra}  val={'1'}></Radio>
                            <div className={style.innerContent}>
                               <p>設置為出貨方式</p>
                            </div>
                        </div>
                        <div className={style.Blist} style={{display:ra==='1'?'block':'none'}}>
                            <div className={style.innerContent}>
                                <p>Delivery Option ID</p>
                                <a href="https://package-plus.com/2023/12/how-to-install-package-circular-packaging-on-shopline/#DeliveryOptionID" target='_blank'>(如何找到Delivery Option ID?)</a> 
                            </div>
                            <div className={style.enterWrap}>
                                <div className={style.inpWrap}>
                                    <Input
                                        val={val}
                                        setVal={(data) => { setVal(data) }}
                                        placeholder={''}
                                        type={'text'}
                                    ></Input> 
                                </div>
                                <span onClick={handleClick} 
                                    style={{ backgroundImage: `url(${Create})` }}>
                                </span>
                            </div>
                            <ul className={style.innerList}>
                                {
                                    items.map((item:any,inx:number,back:any)=>(
                                        <Fragment key={item.id}>
                                          <ListCard 
                                          data2={item} 
                                          inx={inx} 
                                          callback={back} 
                                          handleItem={(data)=>{addItem(data)}} 
                                          filterId={(data)=>{removeItem(data)}}
                                          ></ListCard>
                                        </Fragment>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className={style.comfirmWrap}>
                            <div className={style.btnWrap}>
                                <Button
                                    type={'submit'}
                                    title={'儲存'}
                                    bgcolor='green'
                                    // pic={DownPic.src}
                                    fn={() => { }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Alert toggle={errMess} status={errStatus} message={err} backToggle={(data)=>{setErrMess(data)}}></Alert>
        </div>
    )
}


const ListCard=({data2,inx,handleItem,callback,filterId}:Listcard)=>{
    const handleClick=()=>{
        if(callback.length!==inx){
            filterId(data2.id)
        }
    }


    return (
        <>
            <li>
                <div className={style.inputWrap}>
                    <p>{data2.inner}</p>
                </div>
                <span onClick={handleClick} 
                style={{ backgroundImage: `url(${Trash})` }}></span>
            </li> 
        </>
    )
}

export default Home;