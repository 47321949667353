import { Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "./home";
import RequireAuth from './components/requireAuth/requireAuth';
import Error from './errorpage';

const CustomRoutes = () => {

    console.log=()=>{}//將console隱藏

    return(
        <BrowserRouter>
            <Routes>
                <Route element={<RequireAuth/>}>
                    <Route  path="/" element={<Home/>} ></Route>
                </Route>
                <Route  path="/error" element={<Error/>} ></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default CustomRoutes;