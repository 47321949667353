import { FC, useState } from 'react';
import style from './input.module.scss';
// import SearchPic from '@/public/search.svg';
// import Eyes from '@/public/input_eyes.svg';

interface Input {
    type?: string;
    val: any;
    setVal: (data: any) => void;
    placeholder?: string;
    disabled?: boolean;
    search?: boolean;
}

const Input: FC<Input> = ({ type, val, setVal, placeholder, disabled, search }) => {
    const [passtype, setPasstype] = useState(false);

    return (
        <div className={style.inputWrap}>
            {/* {
                search &&
                <div className={style.searchPicWrap}>
                    <Image src={SearchPic}
                        alt="search"
                        width={16}
                        height={16} />
                </div>
            }
            {
                type === 'password' &&
                <span className={style.eyes} onClick={() => { setPasstype(!passtype) }}>
                    <Image src={Eyes}
                        alt="eyes"
                        width={20}
                        height={14} />
                </span>
            } */}

            <input
                type={type ? (passtype ? 'text' : type) : 'text'}
                style={{ padding: search ? '0 12px 0 30px' : '0 12px 0 12px' }}
                value={val}
                onChange={(e) => { setVal(e.target.value) }}
                maxLength={50}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete='off' />
        </div>
    )
}

export default Input;