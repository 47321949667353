import {useState,useEffect} from 'react';
import Radio from '@mui/material/Radio';
import { green} from '@mui/material/colors';

interface Back{
    back:(data:string|number)=>void;
    raWay:string|number;
    val:string|number;
    dis?:boolean;
}

export default function RadioButtons({back,raWay,val,dis}:Back) {
    const [selectedValue, setSelectedValue] = useState<string|number>('');
    const [che,setChe]=useState(false);
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedValue(e.target.value);
    //   console.log(e.target.value)
      if(val===e.target.value){
        back(e.target.value);
      }
    };

    useEffect(()=>{
        if(raWay!==''){
          if(val ===`${raWay}`){
            setSelectedValue(`${raWay}`);
          }
        }
        if(`${raWay}`!=='' && selectedValue!==''){
            if(selectedValue === `${raWay}` ){
              setChe(true);
          }else{
              setChe(false);
          } 
        }
    },[raWay,selectedValue])

    // useEffect(()=>{
    //     if(selectedValue === raWay ){
    //         setChe(true);
    //     }else{
    //         setChe(false);
    //     } 
    // },[selectedValue]);
  
    return (
      <div>
        <Radio
        //   checked={selectedValue === val}
          checked={dis?false:che}
          onChange={handleChange}
          value={val}
          name="radio-buttons"
          inputProps={{ 'aria-label': 'A' }}
          disabled={dis}
        //   color="success"
          sx={{
            color: green[500],
            '&.Mui-checked': {
              color: green[500],
            },
          }}
        />
      </div>
    );
  }